import React from 'react'
import { Marker, Circle } from 'react-leaflet';
import { SchoolLocationIcon } from './SchoolLocationIcon';
import MarkerPopup from './MarkerPopup';

const Markers = ({ candidate, radiusFactor }) => {

  const markers = candidate.escolas.map((escola, index) => {

    return (
      <Marker key={index} position={[escola.longitude, escola.latitude]} icon={SchoolLocationIcon} >
        <MarkerPopup school={escola} />
        {escola.quantidadeVotos !== 0
          ?
          <Circle
            center={{ lat: escola.longitude, lng: escola.latitude }}
            fillColor="#f03"
            color="red"
            fillOpacity="0.2"
            // radius={escola.quantidadeVotos * radiusFactor}
            radius={escola.quantidadeVotos * radiusFactor}

          > <MarkerPopup school={escola} />
          </Circle>
          : null}
      </Marker>
    )
  });

  return (
    <>
      {markers}
    </>

  )
};

export default Markers;
